import { createFromBaseTheme } from "@/style/baseTheme"; 
import { createTheme, ThemeOptions } from "@mui/material";

import logo from '/images/logo.png'
import logoBar from '/images/logoBar.png'

const customConstants: ThemeOptions =  {
  palette: {
    primary: {
      main: "#0d1321",
    },
    secondary: {
      main: "#3ce99e",
    }
  },
  custom: {
    constants: {
      logoImage: logo,
      logoBarImage: logoBar,
    }
  }

}; 
const baseCustomizedTheme = createFromBaseTheme(customConstants); 
const customizations: ThemeOptions = {} 
const newTheme = createTheme(baseCustomizedTheme, customizations); 

export default newTheme;
