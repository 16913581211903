import { Box, Typography, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import InvoicesChart from "./components/InvoicesChart";
import InvoicesTable from "./Table/InvoicesTable";
import InvoicesFilter from "./components/InvoicesFilter";
import InvoicesPagination from "./components/InvoicesPagination";
import PayDialog from "../PaymentPlatform/PayDialog";
import { ContentHeader } from "../ContentHeader";
import Settings from "../../settings";
import { useInvoices } from "./hooks/useInvoices";

const paymentProvider = Settings?.paymentProvider ?? "stripe";

const InvoicesContent = ({ type }) => {
  const { t } = useTranslation();
  const {
    invoices,
    loaded,
    errors,
    initialFilter,
    initialItemsPage,
    handleItemsOffset,
    applySearchFilter,
    fetchData
  } = useInvoices({ type })

  return (
    <>
      <PayDialog
        platformName={paymentProvider}
        onPaymentSuccessful={fetchData} 
        onClose={() => {}}
      />
      <ContentHeader
        title={type === 'electricity' ? t('common:text.invoices_view_title') : t('common:text.invoices_view_title_gas')}
        addButton={false}
        refreshButton={true}
        refreshClickMethod={fetchData}
      />

      {!errors ? 
        <div>
          {loaded && invoices?.length === 0 ?
            <Alert sx={{mb: 2}} severity="info">
              {t('common:text.invoices_view_empty_list')}
            </Alert>
            :
            <>
              <Box mb={2}>
                <Typography variant={"h6"}>
                  {t('common:text.invoices_contracts_chart')}
                </Typography>
              </Box>

              <Box mb={2}>
                <InvoicesChart invoices={invoices} type={type}/>
              </Box>

              <Box mb={2}>
                <InvoicesFilter 
                  initialFilters={initialFilter} 
                  initialItemsPage={initialItemsPage}
                  applySearchFilter={applySearchFilter}
                  type={type}
                />
              </Box>

              <Box mb={2}>
                <InvoicesPagination onItemsOffsetChange={handleItemsOffset} type={type}/>
              </Box>

              <InvoicesTable type={type}/>

              <Box mt={2}>
                <InvoicesPagination onItemsOffsetChange={handleItemsOffset} type={type} />
              </Box>
            </>
          }
        </div>
      :
        <Alert severity="warning">{t("common:text.invoices_error_fetching")}</Alert>
      }
        
    </>
  );
}

export default InvoicesContent;
