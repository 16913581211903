import { useEffect, useState } from "react";
import * as invoicesActionCreators from "../actions/invoices";
import * as invoicesActionCreatorsGas from '../actions/invoicesGas';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { RootState } from "@/store";
import { IFilter } from "../types/filter";
import { useSettingsContext } from "@/components/Settings/hooks/useSettingsContext";

export interface IUseInvoicesProps {
  type: 'electricity' | 'gas';
}

export const useInvoices = ({ 
  type
}: IUseInvoicesProps) => {
  const Settings = useSettingsContext();
  const dispatch = useDispatch();
  const params = useParams();

  const invoices = useSelector((state: RootState) => state.invoices[type].items ?? []);
  const itemsOffset = useSelector((state: RootState) => state.invoices[type].itemsOffset);
  const itemsPage = useSelector((state: RootState) => state.invoices[type].itemsPage);
  const loaded = useSelector((state: RootState) => state.invoices[type].loaded);
  const errors = useSelector((state: RootState) => state.invoices[type].errors);
  const currentFilter = useSelector((state: RootState) => state.invoices[type].filters.currentFilter);
  const token = useSelector((state: RootState) => state.auth.token);


  const [initialFilter, setInitialFilter] = useState<IFilter[]>([]);
  const [initialItemsPage, setInitialItemsPage] = useState<number | null>(Settings?.invoices?.itemsPerPage ?? 100);

  useEffect(() => { //Runs when component is mounted/unmounted
    if(type === 'electricity'){
      dispatch(invoicesActionCreators.fetchFilters(token));
    }
    else{
      dispatch(invoicesActionCreatorsGas.fetchInvoices(token, true));
    }
  }, []);

  useEffect(() => {  //Runs when the url parameter 'contractName' changes
    if (params?.contractName) {
      setInitialFilter([
        {
          category: { type: "autocomplete", renderText: "Contracte", searchField: "polissa_id.name" },
          value: [params?.contractName], 
          type: 'contains'
        }
      ]);
      dispatch(invoicesActionCreators.fetchInvoices(token, false, `('polissa_id.name', 'in', ['${params.contractName}'])`));
    } else {
      setInitialFilter([]);
      if(type === 'electricity'){
        dispatch(invoicesActionCreators.fetchInvoices(token, false));
      }
      else{
        dispatch(invoicesActionCreatorsGas.fetchInvoices(token, false));
      }
    }
    setInitialItemsPage(null);
  }, [params?.contractName]);

  const fetchData = () => {
    if(type === 'electricity'){
      dispatch(invoicesActionCreators.fetchInvoices(token, false, currentFilter, itemsOffset, itemsPage));
    }
    else{
      dispatch(invoicesActionCreatorsGas.fetchInvoices(token, false, itemsOffset, currentFilter, itemsPage));
    }
  };

  const applySearchFilter = (newFilters: string, newItemsPage: number) => {
    const newItemsOffset = newItemsPage !== itemsPage ? 0 : itemsOffset;
    setInitialItemsPage(newItemsPage);

    if(type === 'electricity'){
      dispatch(invoicesActionCreators.fetchInvoices(token, false, newFilters, newItemsOffset, newItemsPage));
    }
    else{
      dispatch(invoicesActionCreatorsGas.fetchInvoices(token, false, newItemsOffset, newFilters, newItemsPage));
    }
  };

  const handleItemsOffset = (newOffset: number) => {
    if(type === 'electricity'){
      dispatch(invoicesActionCreators.fetchInvoices(token, false, currentFilter, newOffset, itemsPage));
    }
    else{
      dispatch(invoicesActionCreatorsGas.fetchInvoices(token, false, newOffset, currentFilter, itemsPage));
    }
  }; 

  return { 
    invoices,
    loaded,
    errors,
    initialFilter,
    initialItemsPage,
    fetchData,
    applySearchFilter,
    handleItemsOffset
  }
}

