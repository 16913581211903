import { createBoilerplateTheme } from "@gisce/oficina-virtual-components";
import { 
  createTheme,
  ComponentsOverrides,
  ComponentsVariants,
  ThemeOptions,
  ChipClassKey,
  ChipProps,
  ButtonClassKey,
  ButtonProps,
} from "@mui/material";

declare module '@mui/material/styles' {
  interface ComponentNameToClassKey {
    MuiButtonPayment: ButtonClassKey;
    MuiChipedPaymentStatus: ChipClassKey;
  }

  interface ComponentsPropsList {
    MuiButtonPayment: Partial<ButtonProps>;
    MuiChipedPaymentStatus: Partial<ChipProps>;
  }

  interface Components {
    MuiButtonPayment?: {
      defaultProps?: ComponentsPropsList['MuiButtonPayment'];
      styleOverrides?: ComponentsOverrides<Omit<Theme, 'components'>>['MuiButtonPayment'];
      variants?: ComponentsVariants['MuiButtonPayment'];
    },
    MuiChipedPaymentStatus?: {
      defaultProps?: ComponentsPropsList['MuiChipedPaymentStatus'];
      styleOverrides?: ComponentsOverrides<Omit<Theme, 'components'>>['MuiChipedPaymentStatus'];
      variants?: ComponentsVariants['MuiChipedPaymentStatus'];
    }
  }
}

export const createFromBaseTheme = (customConstants?: ThemeOptions) => {
  const defaultGisceConstants: ThemeOptions = {
    palette: {
      primary: {
        main: "#6e96d8",
      },
      secondary: {
        main: "#d8af6e",
      }
    },
  };

  const boilerplatedTheme = createBoilerplateTheme(customConstants ?? defaultGisceConstants);

  return createTheme(boilerplatedTheme, {
    components: {
      MuiButtonPayment: {
        defaultProps: { color: 'primary' }
      },
      MuiChipedPaymentStatus: {
        defaultProps: { variant: 'outlined' },
        variants: [
          {
            props: { color: "warning", variant: "filled" },
            style: {
              color: boilerplatedTheme.palette.warning.contrastText,
              backgroundColor: boilerplatedTheme.palette.warning.main
            }
          },
          {
            props: { color: "warning", variant: "outlined" },
            style: {
              color: boilerplatedTheme.palette.warning.main,
              borderColor: boilerplatedTheme.palette.warning.main
            }
          },
          {
            props: { color: "success", variant: "filled" },
            style: {
              color: boilerplatedTheme.palette.success.contrastText,
              backgroundColor: boilerplatedTheme.palette.success.main
            }
          },
          {
            props: { color: "success", variant: "outlined" },
            style: {
              color: boilerplatedTheme.palette.success.main,
              borderColor: boilerplatedTheme.palette.success.main
            }
          },
          {
            props: { color: "error", variant: "filled" },
            style: {
              color: boilerplatedTheme.palette.error.contrastText,
              backgroundColor: boilerplatedTheme.palette.error.main
            }
          },
          {
            props: { color: "error", variant: "outlined" },
            style: {
              color: boilerplatedTheme.palette.error.main,
              borderColor: boilerplatedTheme.palette.error.main
            }
          }
        ]
      }
    }
  } as ThemeOptions)
}
